import React from 'react'

import './../styles/pageSpecific/services.scss'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import AngledContainer from '../components/UI/Containers/AngledContainer'
import CapabilityGraphic from '../components/UI/Capabilities/CapabilityGraphic/CapabilityGraphic'
import MBSEGraphic from './../images/capabilities/MBSE.png'

import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ThreeBoxRow from '../components/UI/Images/ThreeBoxRow/ThreeBoxRow'
import ServiceIcon from './../images/icons/capabilities/ModelBasedSysEngr.inline.svg'

const MBSEPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Model Based',
    title2: 'Systems Engineering',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Transforming into <span>a Digital Enterprise</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                We utilize the Model Based Systems Engineering (MBSE) approach
                to digital engineering to deliver the best possible solutions
                and services for our customers. This approach allows us to
                transform document-based data into a product-centric,
                integrated, digital enterprise.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityGraphic
            src={MBSEGraphic}
            title="Diverse Models displayed on computer screens being reviewed"
          />
        </SectionContainer>

        {/* Why Us */}
        <div id="detailsDisplayServices_Wrap">
          <AngledContainer
            id="detailsDisplayServices"
            color="navy"
            type="fullWidth"
            svgColor="grayToNavy"
          >
            <SectionHeader
              color="white"
              alignment="center"
              size="med"
              trim="true"
            >
              Providing <span>Expertise In</span>
            </SectionHeader>
          </AngledContainer>
          <ThreeBoxRow
            overlaying={true}
            boxContent={{
              first: {
                img: 'carbon:data-share',
                text: 'Requirements Management',
              },
              second: {
                img: 'carbon:model-alt',
                text: 'Data Modeling',
              },
              third: {
                img: 'carbon:license-maintenance-draft',
                text: 'Test & Evaluation',
              },
            }}
          />
        </div>
        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Model Based Systems Engineering"
            ServiceIcon={ServiceIcon}
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default MBSEPage
